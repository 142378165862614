import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject, filter, map, merge } from 'rxjs';
import { ScreenTypes } from './breakpoints';

export interface DeviceSizeState extends BreakpointState {
    alias: DeviceSizeEnum;
}

export enum DeviceSizeEnum {
    WEB = 'web',
    TABLET = 'tablet',
    MOBILE = 'mobile'
}

@Injectable()
export class DeviceSizeService {
    private sizeChangedSubject = new Subject<DeviceSizeEnum>();
    private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

    public sizeMobile = this.breakpoint.isMatched(ScreenTypes.MOBILE);
    public sizeTablet = this.breakpoint.isMatched(ScreenTypes.TABLET);
    public sizeWeb = this.breakpoint.isMatched(ScreenTypes.WEB);
    public sizeCurrent: DeviceSizeEnum = this.sizeWeb
        ? DeviceSizeEnum.WEB
        : this.sizeTablet
        ? DeviceSizeEnum.TABLET
        : DeviceSizeEnum.MOBILE;
    public sizePrevious: DeviceSizeEnum = this.sizeCurrent;
    public sizeChanged$ = this.sizeChangedSubject.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private zone: NgZone,
        private rendererFactory: RendererFactory2,
        private breakpoint: BreakpointObserver
    ) {
        this.updateClasses();

        merge(
            this.breakpoint.observe(ScreenTypes.MOBILE).pipe(map((x) => ({ alias: DeviceSizeEnum.MOBILE, ...x }) as DeviceSizeState)),
            this.breakpoint.observe(ScreenTypes.TABLET).pipe(map((x) => ({ alias: DeviceSizeEnum.TABLET, ...x }) as DeviceSizeState)),
            this.breakpoint.observe(ScreenTypes.WEB).pipe(map((x) => ({ alias: DeviceSizeEnum.WEB, ...x }) as DeviceSizeState))
        )
            .pipe(filter((x) => x.matches === true && x.alias !== this.sizeCurrent))
            .subscribe({
                next: (state) =>
                    this.zone.run(() => {
                        this.sizePrevious = this.sizeCurrent;
                        this.sizeWeb = state.alias === DeviceSizeEnum.WEB;
                        this.sizeTablet = state.alias === DeviceSizeEnum.TABLET;
                        this.sizeMobile = state.alias === DeviceSizeEnum.MOBILE;
                        this.sizeCurrent = state.alias;
                        this.sizeChangedSubject.next(this.sizeCurrent);
                        this.updateClasses();
                    })
            });
    }

    private updateClasses() {
        if (this.sizeWeb) {
            this.renderer.addClass(this.document.body, 'size-web');
        } else {
            this.renderer.removeClass(this.document.body, 'size-web');
        }
        if (this.sizeTablet) {
            this.renderer.addClass(this.document.body, 'size-tablet');
        } else {
            this.renderer.removeClass(this.document.body, 'size-tablet');
        }
        if (this.sizeMobile) {
            this.renderer.addClass(this.document.body, 'size-mobile');
        } else {
            this.renderer.removeClass(this.document.body, 'size-mobile');
        }
    }
}
