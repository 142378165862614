const MOBILE_LANDSCAPE = '(orientation: landscape) and (max-width: 959.9px)';
const MOBILE_PORTRAIT = '(orientation: portrait) and (max-width: 599.9px)';

const TABLET_LANDSCAPE = '(orientation: landscape) and (min-width: 960px) and (max-width: 1279.9px)';
const TABLET_PORTRAIT = '(orientation: portrait) and (min-width: 600px) and (max-width: 839.9px)';

const WEB_LANDSCAPE = '(orientation: landscape) and (min-width: 1280px)';
const WEB_PORTRAIT = '(orientation: portrait) and (min-width: 840px)';

export const ScreenTypes = {
    MOBILE: `${MOBILE_PORTRAIT}, ${MOBILE_LANDSCAPE}`,
    TABLET: `${TABLET_PORTRAIT} , ${TABLET_LANDSCAPE}`,
    WEB: `${WEB_PORTRAIT}, ${WEB_LANDSCAPE} `,

    MOBILE_LANDSCAPE: `${MOBILE_LANDSCAPE}`,
    TABLET_LANDSCAPE: `${TABLET_LANDSCAPE}`,
    WEB_LANDSCAPE: `${WEB_LANDSCAPE}`,

    MOBILE_PORTRAIT: `${MOBILE_PORTRAIT}`,
    TABLET_PORTRAIT: `${TABLET_PORTRAIT} `,
    WEB_PORTRAIT: `${WEB_PORTRAIT}`
};
