import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'public-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    standalone: true
})
export class RedirectComponent {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute
    ) {
        this.document.defaultView.location.replace(this.route.snapshot.data.url);
    }
}
