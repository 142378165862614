import { ENVIRONMENT_INITIALIZER, inject, makeEnvironmentProviders } from '@angular/core';
import { DeviceSizeService } from './device-size.service';

export const provideDeviceSize = () =>
    makeEnvironmentProviders([
        DeviceSizeService,
        {
            provide: ENVIRONMENT_INITIALIZER,
            multi: true,
            useValue: () => inject(DeviceSizeService)
        }
    ]);
