import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { DeviceSizeService } from '@mymahi/device-size';
import { environment } from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Beacon: any;

@Component({
    selector: 'public-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, MatButtonModule, MatMenuModule, MatIconModule]
})
export class NavigationComponent {
    public appLink: string;

    public get sizeWeb() {
        return this.deviceSize.sizeWeb;
    }

    constructor(private deviceSize: DeviceSizeService) {
        this.appLink = `https://app.${environment.baseUrl}`;
    }

    public displayContactUs() {
        if (Beacon != null) {
            Beacon('open');
            Beacon('navigate', '/ask/');
        }
    }
}
