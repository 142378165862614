import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_ID, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Meta, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideDeviceSize } from '@mymahi/device-size';
import { provideMarkdown } from 'ngx-markdown';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: APP_ID, useValue: 'serverApp' },
        importProvidersFrom(MatButtonModule, MatIconModule, MatMenuModule),
        Meta,
        provideRouter(
            routes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
            withRouterConfig({ paramsInheritanceStrategy: 'always' })
        ),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideClientHydration(),
        provideMarkdown(),
        provideDeviceSize()
    ]
};
