// export const ENV_DEVELOPMENT = {
//     production: false,
//     api: {
//         graphql: 'http://localhost:3000/graphql',
//         graphql_ws: 'ws://localhost:3000/graphql/ws'
//     },
//     auth: {
//         AUTHORITY: 'http://localhost:3100',
//         CLIENT_ID: 'St6ds9bGk3d7cuczEfyyOAQhXXMPLuvI',
//         RESOURCE: 'http://api.dev.mymahi.com',
//         SCOPE: 'openid profile email offline_access'
//     },
//     baseUrl: 'dev.mymahi.com'
// };

export const ENV_DEVELOPMENT = {
    production: false,
    api: {
        graphql: 'https://api.staging.mymahi.com/graphql',
        graphql_ws: 'wss://api.staging.mymahi.com/graphql/ws'
    },
    auth: {
        AUTHORITY: 'https://identity.staging.mymahi.com',
        CLIENT_ID: 'zXCcy2wLk77JNOp3Q861cMdP0HeOvSQ0',
        RESOURCE: 'https://api.staging.mymahi.com',
        SCOPE: 'openid profile email offline_access'
    },
    baseUrl: 'staging.mymahi.com'
};

export const ENV_STAGING = {
    production: true,
    api: {
        graphql: 'https://api.staging.mymahi.com/graphql',
        graphql_ws: 'wss://api.staging.mymahi.com/graphql/ws'
    },
    auth: {
        AUTHORITY: 'https://identity.staging.mymahi.com',
        CLIENT_ID: 'zXCcy2wLk77JNOp3Q861cMdP0HeOvSQ0',
        RESOURCE: 'https://api.staging.mymahi.com',
        SCOPE: 'openid profile email offline_access'
    },
    baseUrl: 'staging.mymahi.com'
};

export const ENV_PRODUCTION = {
    production: true,
    api: {
        graphql: 'https://api.mymahi.com/graphql',
        graphql_ws: 'wss://api.mymahi.com/graphql/ws'
    },
    auth: {
        AUTHORITY: 'https://identity.mymahi.com',
        CLIENT_ID: 'cZ8n4T4NZXPl5q8ZNcPdJ1GuSwFoMWQO',
        RESOURCE: 'https://api.mymahi.com',
        SCOPE: 'openid profile email offline_access'
    },
    baseUrl: 'mymahi.com'
};
