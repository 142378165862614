import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DeviceSizeService } from './device-size.service';

export const checkLessonsAccessGuard = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
    const deviceSize = inject(DeviceSizeService);
    if (route.data?.['size'] != null) {
        const size: 'web' | 'tablet' | 'mobile' = route.data['size'];
        if (deviceSize.sizeCurrent !== size) {
            return of(false);
        }
    }
    return of(false);
};
