import { Routes } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.routes')
    },
    {
        path: 'features',
        loadChildren: () => import('./features/features.routes')
    },
    {
        path: 'pricing',
        loadChildren: () => import('./pricing/pricing.routes')
    },
    {
        path: 'about-us',
        loadChildren: () => import('./about-us/about-us.routes')
    },
    {
        path: 'about',
        redirectTo: 'about-us'
    },
    {
        path: 'programmes',
        title: 'Programmes',
        loadChildren: () => import('./programmes/programmes.routes')
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./privacypolicy/privacypolicy.routes')
    },
    {
        path: 'terms-of-use',
        loadChildren: () => import('./termsofuse/termsofuse.routes')
    },
    {
        path: 'trial',
        data: {
            url: 'https://info.mymahi.com/request-a-free-trial'
        },
        component: RedirectComponent
    },
    {
        path: 'lesson/Introduction-to-RealMe',
        data: {
            url: 'https://rebrand.ly/Introduction-to-RealMe'
        },
        component: RedirectComponent
    },
    {
        path: 'lesson/understanding-debt',
        data: {
            url: 'https://rebrand.ly/Understanding-Debt-Life-Education-Trust-SMARTS'
        },
        component: RedirectComponent
    },
    {
        path: 'lesson/saving-for-future-you',
        data: {
            url: 'https://rebrand.ly/Savings-Life-Education-Trust'
        },
        component: RedirectComponent
    },
    {
        path: 'lesson/deferred-payment',
        data: {
            url: 'https://rebrand.ly/Deferred-Payment-Life-Education-Trust'
        },
        component: RedirectComponent
    },
    {
        path: 'lesson/kiwisaver',
        data: {
            url: 'https://rebrand.ly/KiwiSaver-Life-Education-Trust'
        },
        component: RedirectComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];
